import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "/src/containers/navbar";
import BacThemeProvider from "/src/providers/bac-theme-provider";
import { transformAsset } from "/src/redux/reducers/space-reducer";
import config from "/src/config.js";
import SEO from "../../components/seo";
import { getAssetsThunk, getSpotsByAssetThunk, setCurrentAsset } from "../../redux/actions/misc-actions";
import AssetSpots from "../../components/asset-spots";
import Contact from "/src/components/home/contact";
import Footer from "/src/components/footer";
import SetupContainer from "/src/setup-container";

const AssetSpotsPage = ({ data }) => {
  const dispatch = useDispatch();
  const region = useSelector((state) => state.region.region);
  const asset = transformAsset({ ...data.asset, id: data.asset.asset_id });
  const assetSpots = useSelector((state) => state.misc.spots[asset?.id]);
  const assets = useSelector((state) => state.misc.assets);
  const assetsStatus = useSelector((state) => state.misc.assetsStatus);
  const [assetInfo, setAssetInfo] = useState(asset);

  const spots = assetSpots || [];

  useEffect(() => {
    dispatch(setCurrentAsset(asset));
    dispatch(getSpotsByAssetThunk(asset.id));
    dispatch(getAssetsThunk({ numberPerPage: 0 }));
  }, []);

  useEffect(() => {
    if (assetsStatus === "success") {
      const newStateAsset = assets.find((as) => as.id === asset.id);
      setAssetInfo(newStateAsset);
    }
  }, [assetsStatus]);

  return (
    <>
      <SetupContainer />
      <SEO
        title={`${config.CLIENT_NAME} - ${asset.city.name} ${asset.name}`}
        description={asset.description}
        image={encodeURI(asset.thumb_url) || ""}
      />
      <BacThemeProvider assetId={asset.asset_id}>
        <Navbar />
        <AssetSpots spots={spots} region={region} asset={assetInfo} />
        <Contact />
        <Footer />
      </BacThemeProvider>
    </>
  );
};

export default AssetSpotsPage;

// This is the page query that connects the data to the actual component. Here you can query for any and all fields
// you need access to within your code. Again, since Gatsby always queries for `id` in the collection, you can use that
// to connect to this GraphQL query.
export const query = graphql`
  query ($id: String) {
    asset(id: { eq: $id }) {
      id
      asset_id
      address
      description
      latitude
      longitude
      logo_light_url
      logo_dark_url
      name
      styles
      thumb_url
      type
      dossier_advertising
      city {
        name
      }
      config {
        show_space_price
      }
    }
  }
`;
